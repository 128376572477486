@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #7a6ff9;
  transition: 0.25s ease-in-out;
}

a:hover {
  text-decoration: none;
  color: #5749f7;
}

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #f44336 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #8bc34a !important;
}

.Toastify__toast-container {
  width: 350px !important;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  height: 300px !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  max-width: 800px !important;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview {
  overflow: auto;
}

.se-math-preview {
  max-height: 350px;
}


.wrs_modal_dialogContainer .wrs_button[title="Superscript (Cmd+Up)"] img {
  /* display: none; */
  content:url("images/logo.png");
  width: 33px;
  height: 33px;
  margin: 0;
  object-fit: cover;
}
.wrs_modal_wrapper.wrs_modal_desktop {
  width: 1000px;
}

.ck-editor__editable_inline {
  height: 300px;
  overflow-y: auto;
}

.html-parser img {
  object-fit: contain;
}
.html-parser {
  font-family: "Bebeboo" !important;
}

.html-parser p {
  margin-left: 4px !important;
}

@font-face {
  font-family: "Bebeboo";
  font-weight: 400;
  src: local("Bebeboo"),
    url(./assets/fonts/Bebeboo-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Bebeboo";
  font-weight: 600;
  src: local("Bebeboo"),
    url(./assets/fonts/Bebeboo-SemiBold.ttf) format("truetype");
}