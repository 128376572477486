.logo {
  width: 150px;
}

.logoSquared {
  height: 47px;
  height: 47px;
}

.navIcon {
  color: #ffffff;
  margin-left: 0.75rem;
}

.link {
  text-decoration: none;
}

.activeLink {
  text-decoration: none;
}

.activeLink div {
  background: #5749f7;
}
