.PhoneInput {
  height: 40px;
  width: 100%;
}

.PhoneInput .PhoneInputCountry {
  border-left: 1px solid #cecece;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  margin-right: 0px;
  padding: 0 8.5px;
  border-radius: 4px 0 0 4px;
}

.PhoneInput .PhoneInputInput {
  height: 21px;
  padding: 8.5px 14px;
  border: 1px solid #cecece;
  outline: none;
  border-radius: 0 4px 4px 0;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
}
